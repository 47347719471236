import imgT1 from "../assets/image/jpg/dev/regular/portfolio-5.jpg";
import imgT2 from "../assets/image/jpg/dev/regular/portfolio-2.jpg";
import imgT3 from "../assets/image/jpg/dev/regular/portfolio-3.jpg";

export const designWorks2 = [
  {
    brand: `8 years`,
    categories: [`online growth manager`],
    thumbnail: imgT1,
    halfWidth: false,
    link: `/online-growth-manager`,
  },
  {
    brand: `5 years`,
    categories: [`product manager`],
    thumbnail: imgT3,
    halfWidth: false,
    link: `/product-manager`,
  },
  {
    brand: `3 years`,
    categories: [`web developer`],
    thumbnail: imgT2,
    halfWidth: false,
    link: `/web-developer`,
  },
];
